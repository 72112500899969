<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" >
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder="请输入条形码" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="批次">
              <a-input v-model="queryParam.batch" placeholder="请输入批次" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-row :gutter="24">
        <a-col :md="2" :sm="24">
          <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
        </a-col>
      </a-row>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/goods_purchase_declaration/modules/CreateForm'
import {
  goods_purchase_declaration_create, goods_purchase_declaration_list, goods_purchase_declaration_delete
} from '@/api/c_wms_goods_purchase'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 创建窗口控制
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '供应商',
          dataIndex: 'corporation'
        },
        {
          title: '批次',
          align: 'center',
          dataIndex: 'batch'
        },
        {
          title: '货品名称',
          align: 'center',
          dataIndex: 'goods_name'
        },
        {
          title: '货品条形码',
          align: 'center',
          dataIndex: 'barcode'
        },
        {
          title: '账册备案序号',
          align: 'center',
          dataIndex: 'item_record_no'
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_purchase_declaration_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDelete (record) {
      console.log(record.id)
      goods_purchase_declaration_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.price = Math.round(values.price * 100)
          values = {
            ...values
          }
          goods_purchase_declaration_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
            }
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    }
  }
}
</script>
