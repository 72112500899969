import request from '@/utils/request'
const requestApi = {
  wms_commodity_list: '/wms/supervised_warehouse/commodity/',
  wms_commodity_create: '/wms/supervised_warehouse/commodity/',
  wms_commodity_update: '/wms/supervised_warehouse/commodity/',
  wms_commodity_delete: '/wms/supervised_warehouse/commodity/',
  commodity_list: '/wms/supervised_warehouse/commodity_id/'
}

export function commodity_list (query) {
  return request({
    url: requestApi.wms_commodity_list,
    method: 'get',
    params: query
  })
}
export function commodity_list_id (query) {
  return request({
    url: requestApi.commodity_list,
    method: 'get',
    params: query
  })
}

export function commodity_create (data) {
  return request({
    url: requestApi.wms_commodity_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}

export function commodity_update (data, commodity_id) {
  return request({
    url: requestApi.wms_commodity_update + commodity_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data
  })
}
export function commodity_delete (commodity_id) {
  return request({
    url: requestApi.wms_commodity_delete + commodity_id + '/',
    method: 'delete'
  })
}
