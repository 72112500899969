var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"form":_vm.form}},[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":10,"offset":5}},[_c('a-form-item',{attrs:{"label":"仓库"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'wms_supervised_warehouse_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]),expression:"[\n              'wms_supervised_warehouse_id',\n              {\n                rules: [\n                  { required: true, message: '' },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"仓库名称","default-active-first-option":false},on:{"search":_vm.handleWmsWarehouseSearch,"change":_vm.handleWmsWarehouseChange}},_vm._l((_vm.dataSource_wms_warehouse),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"库位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'wms_cargo_space_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]),expression:"[\n              'wms_cargo_space_id',\n              {\n                rules: [\n                  { required: true, message: '' },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"库位名称","default-active-first-option":false},on:{"search":_vm.handleWmsCargoSpaceSearch,"change":_vm.handleWmsCargoSpaceChange}},_vm._l((_vm.dataSource_wms_cargo_space),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"货品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'wms_supervised_warehouse_goods_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]),expression:"[\n              'wms_supervised_warehouse_goods_id',\n              {\n                rules: [\n                  { required: true, message: '' },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"货品名称","default-active-first-option":false},on:{"search":_vm.handleWmsGoodsSearch,"change":_vm.handleWmsGoodsChange}},_vm._l((_vm.dataSource_wms_goods),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),(false)?_c('a-form-item',{attrs:{"label":"商品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'wms_supervised_warehouse_commodity_id',
              {
                rules: [
                  { required: true, message: '' },
                ],
              },
            ]),expression:"[\n              'wms_supervised_warehouse_commodity_id',\n              {\n                rules: [\n                  { required: true, message: '' },\n                ],\n              },\n            ]"}],attrs:{"disabled":"","option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"货品名称","default-active-first-option":false},on:{"search":_vm.handleWmsGoodsSearch,"change":_vm.handleWmsGoodsChange}},_vm._l((_vm.dataSource_wms_commodity),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.batch}},[_vm._v(" "+_vm._s(d.batch)+" ")])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]),expression:"['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"破损数量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['broken_count', {rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['broken_count', {rules: [{required: false, message: '必填项，请填写信息'}]}]"}],attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'status',
              {
                rules: [
                  { required: true, message: '请选择' },
                ],
              },
            ]),expression:"[\n              'status',\n              {\n                rules: [\n                  { required: true, message: '请选择' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"选择"}},_vm._l((_vm.commodity_purchase_status),function(val,key){return _c('a-select-option',{key:key,attrs:{"value":key}},[_vm._v(_vm._s(val))])}),1)],1)],1)],1),_c('a-form-item',_vm._b({staticStyle:{"width":"60%","margin":"0 auto"}},'a-form-item',_vm.buttonCol,false),[_c('a-row',[_c('a-col',{attrs:{"span":"6"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.handleSubmit}},[_vm._v("提交")])],1),_c('a-col',{attrs:{"span":"10"}},[_c('a-button',{on:{"click":_vm.handleGoBack}},[_vm._v("返回")])],1),_c('a-col',{attrs:{"span":"8"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }