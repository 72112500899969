<template>
  <a-modal
    title="新建"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="供应商" >
              <a-select
                showSearch
                allow-clear
                v-decorator="['corporation_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入供应商名称"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="this.corporationSearch"
                @change="handleCorporationChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in corporationList" :key="d.id" :value="d.id">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="批次">
              <a-input v-decorator="['batch', {initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="货品">
              <a-select
                showSearch
                v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                placeholder="请输入货品名称"
                style="width: 100%"
                :filter-option="false"
                allow-clear
                :not-found-content="goods_fetching ? undefined : null"
                @search="this.goodsSearch"
                @change="handleGoodsChange"
              >
                <a-spin v-if="goods_fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in goodsList" :key="d.barcode" :value="d.barcode">
                  {{ d.name }}（{{ d.barcode }}）
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="账册备案序号">
              <a-input v-decorator="['item_record_no', {initialValue: '', rules: [{required: true, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { corporation_list } from '@/api/corporation'
import { goods_list } from '@/api/c_wms_goods'
import debounce from 'lodash/debounce'

// 表单字段
const fields = ['id', 'name', 'code', 'wms_commodity_purchase_id']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.corporationSearch = debounce(this.handleCorporationSearch, 800)
    this.goodsSearch = debounce(this.handleGoodsSearch, 800)
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 }
        }
      },
      fetching: false,
      goods_fetching: false,
      corporationList: [],
      goodsList: [],
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleCorporationSearch(undefined)
    this.handleGoodsSearch(undefined)
  },
  methods: {
    handleCorporationSearch (value) {
      this.corporationList = []
      this.fetching = true
      corporation_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.corporationList = result
        this.fetching = false
      })
    },
    handleCorporationChange (value) {
      if (value === undefined) {
        this.handleCorporationSearch(undefined)
      }
    },
    handleGoodsSearch (value) {
      this.goodsList = []
      this.goods_fetching = true
      goods_list({ name: value }).then(({ data }) => {
        const result = data.entries || []
        this.goodsList = result
      }).finally(() => {
        this.goods_fetching = false
      })
    },
    handleGoodsChange (value) {
      if (value === undefined) {
        this.handleGoodsSearch(undefined)
      }
    }
  }
}
</script>
